<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/arka-logo.jpg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col cols="12" class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3 text-red">
          Benvenuto ad Arkanoè
        </h1>

        <p class="subheading font-weight-regular text-red">
          Qui potrai prenotare le uscite pratiche in mare.
        </p>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <!-- <v-col :class="[
        'col-12',
        lezioni.length == 0 ? 'd-none' : '',
        uscite.length == 0 ? 'col-md-12' : 'col-md-6',
      ]">
        <h2 class="headline font-weight-bold mb-3 text-red">
          Prossime lezioni in aula
        </h2>

        <p v-if="lezioni.length == 0">Non sono ancora state pubblicate le date delle lezioni</p>
        <v-col>
          <div v-for="event in lezioni" :key="event.id" @click="goToPrenota(event)" >
            <v-card class="mt-5 cursor-pointer" style="min-height: 200px" elevation="16">
              <v-card-title class="text-h5 text-left text-red">{{new Date(event.when).toLocaleDateString("it-IT", {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'}).capitalize()}} - {{event.title}}</v-card-title>
              <v-card-subtitle class="text-left">{{event.description}}</v-card-subtitle>
              <v-card-text class="text-left">
                <p :class="(event.availability > 0 ? 'text-green font-weight-bold' : 'text-red font-weight-bold')">Posti disponibili: {{event.availability}}</p>
              </v-card-text>
              <v-card-actions>
                &nbsp;&nbsp;clicca qui per prenotare!
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-col> -->

      <v-col :class="[
        'col-12',
        // uscite.length == 0 ? 'd-none' : '',
        lezioni.length == 0 ? 'col-md-12' : 'col-md-6',
      ]">
        <h2 v-if ="uscite.length == 0" class="headline font-weight-bold mb-3 text-red">
          Non sono ancora state pubblicate uscite in mare
        </h2>

        <v-col>
          <div v-for="event in uscite" :key="event.id" @click="goToPrenota(event)">
            <v-card class="mt-5 cursor-pointer" style="min-height: 200px" elevation="16">
              <v-card-title class="text-h5 text-left text-red">{{new Date(event.when).toLocaleDateString("it-IT", {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'}).capitalize()}} - {{event.title}}</v-card-title>
              <v-card-subtitle class="text-left">{{event.description}}</v-card-subtitle>
              <v-card-text class="text-left">
                <p :class="(event.availability > 0 ? 'text-green font-weight-bold' : 'text-red font-weight-bold')">Posti disponibili: {{event.availability}}</p>
              </v-card-text>
              <v-card-actions>
                &nbsp;&nbsp;clicca qui per prenotare!
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-col>
    </v-row> 


    <loader :loading="loader"/>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Loader from './Loader.vue';

  export default {
    components: { Loader },
    name: 'Home',

    data () {
      return {
        loader: false,
        lezioni: [],
        uscite: [],
      }
    },
    async mounted () {
      this.loader = true;
      const resp = await axios.get('/api/uscite')
      if (resp.status == 200) {
        this.lezioni = resp.data.filter(day => day.type == "Lezione");
        this.uscite = resp.data.filter(day => day.type == "Uscita");
      }
      this.loader = false;
    },
    methods: {
      async goToPrenota(event) {
        if (event.availability > 0) {
          this.$router.push(`/prenota/${event.id}`)
        }
      }
    }
  }
</script>


