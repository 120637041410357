<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <h1>Arkanoè gruppo velico</h1>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer dark padless >
      <v-card class="flex" flat tile >
        <v-card-title class="arkanoe-colors">
          <strong class="subheading cursor-pointer" @click="goTo('https://www.arkanoe.net')">Arkanoè Gruppo Velico</strong>

          <v-spacer></v-spacer>

          <v-btn v-for="icon in icons" :key="icon.icon" class="mx-4" dark icon >
            <v-icon size="24px" @click="goTo(icon.url)">
              {{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>Arkanoè gruppo velico</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    icons: [
      { icon: 'mdi-facebook', url: 'https://www.facebook.com/Arkano%C3%A8-gruppo-velico-30691112756' },
      { icon: 'mdi-instagram', url: 'https://www.instagram.com/arkanoe_gruppovelico/' },
      { icon: 'mdi-phone', url: 'tel:+393355626987' },
    ],
  }),
  methods: {
    async goTo(url) {
      window.open(url, '_blank').focus();
    }
  }
}
</script>

<style>
  .arkanoe-colors {
    background-color: #cc0000 !important;
    border-color: #cc0000 !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .text-red {
    color: #CC0000;
    font-size: 1.2em;
  }
  .text-green {
    color: green;
    font-size: 1.2em;
  }
</style>
