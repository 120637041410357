import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.config.productionTip  = false
Vue.config.devtools       = true
Vue.use(VueRouter)

const router = new VueRouter({routes});

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

new Vue({
  vuetify,
  render: h => h(App),
  router
}).$mount('#app')
