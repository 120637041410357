<template>
  <v-container>
    <v-breadcrumbs divider=">" :items="items"></v-breadcrumbs>

    <v-row class="text-center">

      <v-col cols="12" class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          {{new Date(evento.when).toLocaleDateString("it-IT", {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'}).capitalize()}}
        </h1>

        <h1 class="display-2 font-weight-bold mb-3">
          {{evento.title}}
        </h1>

        <p class="subheading font-weight-regular">
          {{evento.description}}
        </p>
      </v-col>

      <v-col cols="12" class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Posti liberi: {{evento.availability}}
        </h1>
      </v-col>

      <v-col cols="12" class="mb-4">
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  label="Nome e cognome"
                  required
                  outlined
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="phone"
                  :rules="phoneRules"
                  label="Numero di telefono cellulare"
                  required
                  outlined
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Email dove ricevere la conferma prenotazione"
                  required
                  outlined
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-textarea
                  v-model="note"
                  label="Eventuali esigenze che vuoi segnalarci"
                  required
                  outlined
                ></v-textarea>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  class="mr-4"
                  x-large
                  @click="clicPrenota"
                >
                  Prenota
                </v-btn>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
      </v-col>

    </v-row>

    <v-dialog v-model="showDialog" max-width="300">
      <v-card>
        <v-toolbar color="primary" dark>{{messageDialog.message}}</v-toolbar>
        <v-card-text>
          <div class="text text-bold pa-12">Se è un uscita in mare verrai inserito nel gruppo whatsapp</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" text @click="closeDialog" >Ok, capito!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loader :loading="loader"/>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import Loader from './Loader.vue';

  export default {
    components: { Loader },
    name: 'Prenota',

    data () {
      return {
        loader: false,
        items: [ { text: 'Torna alla lista lezione/uscite', disabled: false, href: '/', }],
        evento: {},
        showDialog: false,
        messageDialog: {
          title: '',
          message: '',
        },
        valid: true,
        name: '',
        nameRules: [
          v => !!v || 'Il nome è un campo obbligatorio'
        ],
        phone: '',
        phoneRules: [
          v => !!v || 'Il telefono è un campo obbligatori',
          // v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        email: '',
        emailRules: [
          // v => !!v || 'La mail è un campo obbligatorio',
          v => ( !v || /.+@.+\..+/.test(v) ) || 'E-mail deve essere valida o vuota',
        ],
        note: '',
      }
    },
    async mounted () {
      this.loader = true;
      const resp = await axios.get(`/api/uscite/${this.$route.params.eventId}`)
      if (resp.status == 200) {
        this.evento = resp.data;
      }
      this.loader = false;
    },
    methods: {
      async clicPrenota () {
        this.loader = true;
        const resp = await axios.post(`/api/uscite/${this.$route.params.eventId}`, { name: this.name, phone: this.phone, email: this.email, note: this.note, evento: this.evento })
        this.messageDialog = resp.data
        this.showDialog = true
        this.loader = false;
      },
      async closeDialog () {
        this.showDialog = false;
        this.$router.push('/');
      }
    },
    computed: {

    }
  }
</script>
